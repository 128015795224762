import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

const DigitalSolutions = () => (

  <Switch>
    <Route
      path="/kategoria/women-families-careers-digital-solutions-in-adult-learning"
      component={() => (
        <div style={{ padding: '40px', background: '#b7b7dd', fontSize: '1.4rem' }}>
          <div className="row">
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Coordinator:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Family-Friendly Hungary Centre
                  <br />
                  Nonprofit Public Benefit Ltd.
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Tibor Zsigmond project manager
                </div>
                <div>
                  Mobile: +36 70 512 86 95
                  <br />
                  E-mail: zsigmond.tibor@csalad.hu
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.csalad.hu/digitalsolutions" rel="noreferrer">www.csalad.hu/digitalsolutions</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Hungarian Partner:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  {'Association for Women\'s Career'}
                  <br />
                  Development in Hungary
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Mrs. Andrea Ferenczi president
                </div>
                <div>
                  Mobile +36 30 982 7003
                  <br />
                  E-mail: ferenczi@womenscareer.org
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.womenscareer.org" rel="noreferrer">http://www.womenscareer.org </a>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Czech Partner:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Business & Professional Women CR z.s.
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Ms. Lenka Šťastná president
                </div>
                <div>
                  Mobile: +420 602 383 880
                  <br />
                  Email: lenka.stastna@bpwcr.cz
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.bpwcr.cz" rel="noreferrer">www.bpwcr.cz</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Slovak Partner:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Association of Business Women and Managers
                  <br />
                  Združenie podnikateliek a manažérok
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Mrs. Ivana Kondášová director
                </div>
                <div>
                  Phone: +421 902 221 126
                  <br />
                  Mobile: +421 948 266 993
                  <br />
                  Email: ivanakondas@yahoo.com
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.uspesnezeny.sk" rel="noreferrer">www.uspesnezeny.sk</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      exact
    />
    <Route
      path="/kategoria/nok-csalad-karrier-digitalis-megoldasok-a-felnott-tanulasban"
      component={() => (
        <div style={{ padding: '40px', background: '#b7b7dd', fontSize: '1.4rem' }}>
          <div className="row">
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  KOORDINÁTOR:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Családbarát Magyarország Központ
                  <br />
                  Nonprofit Közhasznú Kft.
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Zsigmond Tibor projektmenedzser
                </div>
                <div>
                  Telefon: +36 70 512 86 95
                  <br />
                  E-mail: zsigmond.tibor@csalad.hu
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.csalad.hu/digitalismegoldasok" rel="noreferrer">www.csalad.hu/digitalismegoldasok</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  MAGYAR PARTNER:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Magyar Női Karrierfejlesztési
                  <br />
                  Szövetség
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Ferenczi Andrea elnök
                </div>
                <div>
                  Telefon +36 30 982 7003
                  <br />
                  E-mail: ferenczi@noikarrier.hu
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.noikarrier.hu" rel="noreferrer">http://www.noikarrier.hu</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  CSEH PARTNER:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Business & Professional Women CR z.s.
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Ms. Lenka Šťastná elnök
                </div>
                <div>
                  Telefon: +420 602 383 880
                  <br />
                  Email: lenka.stastna@bpwcr.cz
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.bpwcr.cz" rel="noreferrer">www.bpwcr.cz</a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ borderLeft: '1px solid #333', paddingLeft: '2rem' }}>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  SZLOVÁK PARTNER:
                </div>
                <div style={{ paddingBottom: '15px' }}>
                  Zdruzenie podnikateliek a manazérok
                </div>
                <div style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                  Mrs. Ivana Kondášová ügyvezetö
                </div>
                <div>
                  Telefon: +421 902 221 126
                  <br />
                  +421 948 266 993
                  <br />
                  Email: ivanakondas@yahoo.com
                  <br />
                  <a style={{ color: '#333', textDecoration: 'none' }} target="_blank" href="http://www.uspesnezeny.sk" rel="noreferrer">www.uspesnezeny.sk</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      exact
    />
  </Switch>
);

export default DigitalSolutions;
