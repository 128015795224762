import {
  decodeHtml, fromJson, processSurvey, sleep,
} from '@csalad-2021/common';
import {
  attempt, find, isArray, isError, map, omitBy, reduce, set,
} from 'lodash';
import { DateTime } from 'luxon';

import {
  BASE_URL, CategoryStatus, ImageCrop, ImageCropRatio, NoImageFile,
} from 'consts';

export * from './path';

export { default as provider } from './provider';

export {
  decodeHtml, fromJson, processSurvey, sleep,
};

const fixImageRatio = ({
  x, y, width, height,
}, ratio) => {
  const newHeight = width / ratio;
  return {
    x,
    width,
    y: Math.max(0, Math.round(y - (newHeight - height) / 2)),
    height: newHeight,
  };
};

const getCroppingImageStyles = (imageStyles, cropId) => {
  const croppers = attempt(JSON.parse, imageStyles);
  if (!croppers || isError(croppers)) {
    return null;
  }

  const { primary, secondary } = croppers;
  const primaryCrop = primary?.[cropId]?.pixels;
  if (primaryCrop) {
    return primaryCrop;
  }
  const secondaryCrop = secondary?.[cropId]?.pixels;
  if (secondaryCrop) {
    return { ...secondaryCrop, secondary: true };
  }
  const { wideCrop, narrowCrop } = croppers;
  const legacy = wideCrop?.pixels || narrowCrop?.pixels;
  if (legacy) {
    return fixImageRatio(legacy, cropId === ImageCrop.WIDE ? 2 / 1 : 1 / 1);
  }

  return null;
};

export const mapToQueryParam = (obj) => map(
  omitBy(obj, (value) => value === undefined),
  (value, name) => `${name}=${encodeURIComponent(value)}`,
).join('&');

export const getImageUrl = (images, size, cropOptions, noImageUrl) => {
  let image = null;
  if (isArray(images)) {
    if (images[0]) {
      image = images[0]; // eslint-disable-line
    } else if (images[1]) {
      image = images[1]; // eslint-disable-line
    }
  } else {
    image = images;
  }
  const secondaryImage = isArray(images) && images[0] && images[1] ? images[1] : null;
  if (!image && !secondaryImage) {
    if (noImageUrl) {
      return noImageUrl;
    }
    if (noImageUrl === null) {
      return undefined;
    }

    return `/resources/images/${NoImageFile[cropOptions?.cropId] || 'no-image.png'}`;
  }

  const url = `${BASE_URL}/image/${Number.isInteger(image) ? image : image.id}`;
  const secondaryUrl = secondaryImage ? `${BASE_URL}/image/${Number.isInteger(secondaryImage) ? secondaryImage : secondaryImage.id}` : url;
  const crop = cropOptions && getCroppingImageStyles(cropOptions.imageStyles, cropOptions.cropId);

  return [
    crop?.secondary ? secondaryUrl : url,
    mapToQueryParam(crop ? {
      cx: crop.x,
      cy: crop.y,
      cw: crop.width,
      ch: crop.height,
      w: size,
      h: size / ImageCropRatio[cropOptions.cropId],
    } : {
      w: size,
      h: cropOptions !== false ? size / (ImageCropRatio[cropOptions?.cropId] || ImageCropRatio[ImageCrop.SQUARE]) : undefined,
    }),
  ].join('?');
};

export const getImageTitle = (images) => {
  const image = isArray(images) ? images[0] : images;

  return image?.title;
};

export const findCategoryById = (categories, id) => find(categories, (category) => category.id === id);

export const findCategoryBySlug = (categories, slug) => find(categories, (category) => category.slug === slug);

export const calcCategoryColor = ({ id } = {}, categories = []) => {
  const category = findCategoryById(categories, id) || {};
  const { parentId, color } = category;
  if (color) {
    return color;
  }

  const parentCategory = parentId && findCategoryById(categories, parentId);
  const basicColor = parentCategory ? calcCategoryColor(parentCategory, categories) : color;
  return !basicColor || basicColor === '#000' || basicColor === '#000000' || basicColor.startsWith('rgba(0,0,0,') ? undefined : basicColor;
};

export const getDisplayName = (user, showUsername = true, empty = '') => {
  if (!user) {
    return empty;
  }

  const { profile, profile: { displayName, firstName, lastName } = {} } = user;
  const username = showUsername ? user.username : '';
  if (!profile) {
    return username;
  }

  return displayName || (`${lastName || ''} ${firstName || ''}`.trim()) || username;
};

export const getPostCreator = ({ source, createdByUser }) => source || getDisplayName(createdByUser);

export const getDisplayDate = (date) => {
  const luxonDate = DateTime.fromISO(date);
  return date && luxonDate && luxonDate.toFormat('yyyy. LL. dd.');
};

export const forkPromise = (forkFn) => {
  const loadPromises = {};
  const startPromise = (promiseFn, url) => {
    loadPromises[url] = loadPromises[url] || [];

    const promise = new Promise((resolve, reject) => loadPromises[url].push({ resolve, reject }));
    if (loadPromises[url].length === 1) {
      promiseFn()
        .then((response) => {
          while (loadPromises[url].length) {
            loadPromises[url].shift().resolve(response);
          }
          delete loadPromises[url];
        })
        .catch((error) => {
          while (loadPromises[url].length) {
            loadPromises[url].shift().reject(error);
          }
          delete loadPromises[url];
        });
    }

    return promise;
  };

  return forkFn(startPromise);
};

export const getQueryParam = (search, name) => {
  if (isArray(name)) {
    return name.map((item) => getQueryParam(search, item));
  }

  const normalizedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${normalizedName}=([^&#]*)`);
  const results = regex.exec(search);
  return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getLocation = (href) => {
  const match = href.match(/^(?:(https?:)\/\/)?(([^:/?#]*){0,1}(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return match && {
    href,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7],
  };
};

export const createRootCategoryMenuAdapter = (rootCategorySlug) => (categories/* , opened */) => {
  const rootCategory = categories && find(categories, ({ slug }) => slug === rootCategorySlug);
  const filteredCategories = (
    rootCategory && (categories || [])
      .filter(({ parentId }) => parentId === rootCategory.id)
      .map((category) => ({ ...category, parentId: null, status: CategoryStatus.OPENED }))
  ) || categories;

  return {
    categories: filteredCategories,
    opened: reduce(filteredCategories, (result, { id }) => set(result, id, true), {}),
    allowToggle: false,
  };
};
